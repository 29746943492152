.icon_button {
  border-radius: 50%;
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  
  &:not(:last-of-type) {
    margin-right: 4px;
  }
  
  svg {
    width: 24px;
    height: 24px;
  }
}