@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&family=Playfair+Display&display=swap');

html,
body,
#root {
  height: 100%;
}

html:root {
  --gray-light: #d6d6d6;
  --main-background: #f5f5f5;
  --white: #ffffff;
  --orange-light: #ef9d87;
}

html {
  background-color: var(--main-background);
}

body {
  background-color: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
}

body,
p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

#__next {
  height: 100%;
}

.main_wrapper {
  height: 100%;
}

ul li button, ul li a {
  list-style: none;
  padding: unset;
}

li button, li a {
  position: relative;
  width: max-content;

  &:hover::after {
    width: 100%;
  }

  &::after {
    content: '';
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: 0;
    height: 1px;
    transition: all .3s cubic-bezier(.175,.885,.32,1.075);
    background-color: var(--orange-light);
  }
}

button {
  border: unset;
  font-weight: inherit;
  background-color: unset;
  padding: unset;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.4);
  border-radius: 10rem;
  border: 1px solid var(--white);
}

body::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

body::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

html {
  overflow-y: overlay;
}