.tag {
  background-color: #fff;
  transition: background-color 150ms linear;
  color: #212529;
  font-size: 13px;
  padding: 3px 8px;
  margin: 5px 5px 3px 0;
  line-height: 1.33333333;

  &:hover {
    background-color: #f2f2f2;
    transition: background-color 150ms linear;
  }
}