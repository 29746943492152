.count {
  display: inline;
  color: #898989;
}

.category_item {
  display: inline;
  position: relative;
}

.category_wrapper {
  display: inline-block;
}